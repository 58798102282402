


























































import { Component, Vue, Prop } from 'vue-property-decorator'
import { AppUser } from '@/store/auth/types'

@Component({})
export default class UserConfigDialog extends Vue {
  @Prop({ type: Boolean, required: true })
  value!: boolean

  @Prop({ type: Object, required: true })
  user!: AppUser

  valid = false

  rules = {
    required: (v: string) => (v !== undefined && v !== '') || this.$t('app.general.simple_form.error.required'),
    password: (v: string) => (v.toLowerCase() !== this.user.username.toLowerCase()) || this.$t('app.general.simple_form.error.password_username'),
    min: (v: string) => (v !== undefined && v.length >= 4) || this.$t('app.general.simple_form.error.min', { min: 4 }),
    max: (v: string) => (v !== undefined && v.length <= 60) || this.$t('app.general.simple_form.error.max', { max: 60 })
  }

  handleSave () {
    if (this.valid) {
      this.$emit('save', this.user)
      this.$emit('input', false)
    }
  }
}
