
















































































































































































































































import { Component, Mixins } from 'vue-property-decorator'
import SaveMeshDialog from './SaveMeshDialog.vue'
import StateMixin from '@/mixins/state'
import ToolheadMixin from '@/mixins/toolhead'
import { KlipperMesh, ProcessedMesh } from '@/store/mesh/types'
import { Waits } from '@/globals'

@Component({
  components: {
    SaveMeshDialog
  }
})
export default class BedMesh extends Mixins(StateMixin, ToolheadMixin) {
  waits = Waits

  mapScaleLabels = ['min', '0.1', '0.2']
  boxScaleLabels = ['1.0', '1.5', '2.0']

  saveDialogState = {
    open: false,
    existingName: 'default'
  }

  get matrix () {
    return this.$store.state.mesh.matrix
  }

  set matrix (val: string) {
    this.$store.dispatch('mesh/onMatrix', val)
  }

  get mapScale () {
    return this.$store.state.mesh.scale
  }

  set mapScale (val: string) {
    this.$store.dispatch('mesh/onScale', val)
  }

  get boxScale () {
    return this.$store.state.mesh.boxScale
  }

  set boxScale (val: string) {
    this.$store.dispatch('mesh/onBoxScale', val)
  }

  get wireframe () {
    return this.$store.state.mesh.wireframe
  }

  set wireframe (val: string) {
    this.$store.dispatch('mesh/onWireframe', val)
  }

  get flatSurface () {
    return this.$store.state.mesh.flatSurface
  }

  set flatSurface (val: boolean) {
    this.$store.dispatch('mesh/onFlatSurface', val)
  }

  // The available meshes.
  get meshes (): KlipperMesh[] {
    return this.$store.getters['mesh/getBedMeshes']
  }

  // The current processed mesh data, if any.
  get mesh (): ProcessedMesh {
    return this.$store.getters['mesh/getCurrentMeshData'][this.matrix]
  }

  // The current mesh, unprocessed.
  get currentMesh (): KlipperMesh {
    return this.$store.state.printer.printer.bed_mesh
  }

  // If we have a mesh loaded.
  get meshLoaded (): boolean {
    return ('profile_name' in this.currentMesh && this.currentMesh.profile_name.length > 0)
  }

  // If we have a default profile.
  get hasDefault (): boolean {
    return (this.meshes.findIndex(mesh => mesh.profile_name === 'default') > -1)
  }

  // If the printer supports QGL
  get printerSupportsQgl (): boolean {
    const printerSettings = this.$store.getters['printer/getPrinterSettings']()
    return 'quad_gantry_level' in printerSettings
  }

  calibrate () {
    this.sendGcode('BED_MESH_CALIBRATE', Waits.onMeshCalibrate)
  }

  clearMesh () {
    this.sendGcode('BED_MESH_CLEAR')
  }

  loadProfile (name: string) {
    this.sendGcode(`BED_MESH_PROFILE LOAD="${name}"`)
  }

  removeProfile (name: string) {
    this.sendGcode(`BED_MESH_PROFILE REMOVE="${name}"`)
    this.sendGcode('SAVE_CONFIG')
  }

  handleMeshSave (config: {name: string; removeDefault: boolean}) {
    if (config.name !== this.currentMesh.profile_name) {
      this.sendGcode(`BED_MESH_PROFILE SAVE="${config.name}"`)
    }
    if (config.removeDefault) {
      this.sendGcode(`BED_MESH_PROFILE REMOVE="${this.currentMesh.profile_name}"`)
    }
    this.sendGcode('SAVE_CONFIG')
  }

  handleOpenSaveDialog () {
    this.saveDialogState = {
      open: true,
      existingName: this.currentMesh.profile_name
    }
  }
}
