























import { Component, Vue, Watch } from 'vue-property-decorator'

@Component({})
export default class MoonrakerLoadChart extends Vue {
  ready = false

  get chartData () {
    return this.$store.state.charts.moonraker || []
  }

  get options () {
    const o = {
      ...this.$store.getters['charts/getBaseChartOptions']({
        load: '%'
      }),
      series: this.series
    }
    return o
  }

  get series () {
    return this.$store.getters['charts/getBaseSeries']({
      name: 'load',
      encode: { x: 'date', y: 'load' }
    })
  }

  @Watch('chartData', { immediate: true })
  onChartData (data: any) {
    if (data && data.length > 0) this.ready = true
  }
}
