




















































































import { Component, Prop, Mixins } from 'vue-property-decorator'
import StateMixin from '@/mixins/state'
import { Macro } from '@/store/macros/types'

@Component({})
export default class AppMacroBtn extends Mixins(StateMixin) {
  @Prop({ type: Object, required: true })
  macro!: Macro

  @Prop({ type: Boolean, default: false })
  enableParams!: boolean;

  params: { [index: string]: { value: string | number; reset: string | number }} = {}

  get paramList () {
    return Object.keys(this.params)
  }

  /**
   * The formatted run command for a macro.
   */
  get runCommand () {
    let s = this.macro.name
    if (this.params) {
      for (const param of Object.keys(this.params)) {
        s += ` ${param}=${this.params[param].value}`
      }
    }
    return s
  }

  get borderStyle () {
    if (this.macro && this.macro.color !== '') {
      return `border-color: ${this.macro.color} !important; border-left: solid 4px ${this.macro.color} !important;`
    }
    return ''
  }

  mounted () {
    if (!this.macro.config || !this.macro.config.gcode) return []
    if (this.macro.config.gcode) {
      const regex = /params\.(\w*)\|?(default\('?(\w*)'?\))?/gmi
      let match = regex.exec(this.macro.config.gcode)
      do {
        if (match && match[1]) {
          const name = match[1]
          const value = match[3] || ''
          if (!this.params[name]) {
            this.$set(this.params, name, { value, reset: value })
          }
        }
      } while (
        (match = regex.exec(this.macro.config.gcode)) !== null
      )
    }
  }
}
