import { render, staticRenderFns } from "./FileSystemDragOverlay.vue?vue&type=template&id=13c1fb4b&scoped=true&"
import script from "./FileSystemDragOverlay.vue?vue&type=script&lang=ts&"
export * from "./FileSystemDragOverlay.vue?vue&type=script&lang=ts&"
import style0 from "./FileSystemDragOverlay.vue?vue&type=style&index=0&id=13c1fb4b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13c1fb4b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VContainer,VIcon,VOverlay,VRow})
