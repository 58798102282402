
























import { Component, Prop, Mixins } from 'vue-property-decorator'
import StateMixin from '@/mixins/state'

@Component({})
export default class FileSystemDragOverlay extends Mixins(StateMixin) {
  @Prop({ type: Boolean, default: false })
  value!: boolean;
}
