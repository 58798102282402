























































































































import { AppNotification } from '@/store/notifications/types'
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class AppNotificationMenu extends Vue {
  menu = false

  get notifications (): AppNotification[] {
    return this.$store.getters['notifications/getNotifications']
  }

  get notificationsCounter (): number {
    const notifications: AppNotification[] = this.notifications.filter(n => !n.noCount)
    return notifications.length
  }

  get clearableNotifications (): AppNotification[] {
    const notifications: AppNotification[] = this.$store.getters['notifications/getNotifications']
    return notifications.filter(n => n.clear)
  }

  /**
   * Color is determined by type. Pull the highest weighted type.
   */
  get color () {
    if (this.notifications.length <= 0) return 'transparent'
    let c = 'transparent'
    for (const n of this.notifications) {
      if (n.type === 'warning' && c !== 'error') c = 'warning'
      if (n.type === 'error' && c !== 'error') {
        c = 'error'
        break
      }
    }
    return c
  }

  get badgeColor () {
    if (this.color === 'transparent') return 'info'
    return this.color
  }

  get isMobile () {
    return this.$vuetify.breakpoint.mobile
  }

  /**
   * If no defined icon, pull from a standard set based on notification type.
   */
  icon (n: AppNotification) {
    if (n.icon) return n.icon
    if (n.type === 'info' || n.type === 'success') return '$info'
    if (n.type === 'warning') return '$warning'
    if (n.type === 'error') return '$error'
    return '$error'
  }

  classes (n: AppNotification) {
    return `notification-${n.type}`
  }

  handleClear (n: AppNotification) {
    this.$store.dispatch('notifications/clearNotification', n)
  }

  handleClearAll () {
    this.$store.dispatch('notifications/clearAll')
  }
}
