























































import { Component, Mixins, Prop, Watch, Ref } from 'vue-property-decorator'
import StateMixin from '@/mixins/state'
import Console from './Console.vue'
import { ConsoleEntry } from '@/store/console/types'

@Component({
  components: {
    Console
  }
})
export default class ConsoleCard extends Mixins(StateMixin) {
  @Prop({ type: Boolean, default: true })
  enabled!: boolean

  @Ref('console') console!: Console

  get hideTempWaits (): boolean {
    return this.$store.state.config.uiSettings.general.hideTempWaits
  }

  set hideTempWaits (value: boolean) {
    this.$store.dispatch('config/saveByPath', {
      path: 'uiSettings.general.hideTempWaits',
      value,
      server: true
    })
  }

  get items (): ConsoleEntry[] {
    return this.$store.getters['console/getConsoleEntries']
  }

  get inLayout (): boolean {
    return (this.$store.state.config.layoutMode)
  }

  get autoScroll () {
    return this.$store.state.console.autoScroll
  }

  set autoScroll (value: boolean) {
    this.$store.dispatch('console/onUpdateAutoScroll', value)
    if (value) {
      this.console.scrollToBottom()
    }
  }

  @Watch('inLayout')
  inLayoutChange (inLayout: boolean) {
    if (!inLayout) {
      this.console.scrollToBottom()
    }
  }

  handleCollapseChange (collapsed: boolean) {
    if (!collapsed) {
      this.console.scrollToBottom()
    }
  }
}
