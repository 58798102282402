





















import { Component, Mixins } from 'vue-property-decorator'
import StateMixin from '@/mixins/state'
import ToolheadMoves from '@/components/widgets/toolhead/ToolheadMoves.vue'
import ExtruderMoves from '@/components/widgets/toolhead/ExtruderMoves.vue'
import ExtruderSelection from '@/components/widgets/toolhead/ExtruderSelection.vue'
import ToolheadPosition from '@/components/widgets/toolhead/ToolheadPosition.vue'
import ZHeightAdjust from '@/components/widgets/toolhead/ZHeightAdjust.vue'
import SpeedAndFlowAdjust from '@/components/widgets/toolhead/SpeedAndFlowAdjust.vue'

@Component({
  components: {
    ToolheadMoves,
    ExtruderMoves,
    ExtruderSelection,
    ToolheadPosition,
    ZHeightAdjust,
    SpeedAndFlowAdjust
  }
})
export default class Toolhead extends Mixins(StateMixin) {
  get multipleExtruders () {
    return this.$store.getters['printer/getExtruders'].length > 1
  }
}
