






























































import { Component, Mixins, Watch } from 'vue-property-decorator'
import { SocketActions } from '@/api/socketActions'
import StateMixin from '@/mixins/state'
import StatusControls from './StatusControls.vue'
import StatusTab from './StatusTab.vue'
import ReprintTab from './ReprintTab.vue'

@Component({
  components: {
    StatusControls,
    StatusTab,
    ReprintTab
  }
})
export default class PrinterStatusCard extends Mixins(StateMixin) {
  tab = 0

  // If the user has no history plugin, and there's no print running..
  // then hide the collapse control.
  get supportsHistoryComponent () {
    return this.$store.getters['server/componentSupport']('history')
  }

  get collapsable () {
    const filename = this.$store.state.printer.printer.print_stats.filename
    return (
      this.printerPrinting ||
      this.supportsHistoryComponent ||
      filename !== ''
    )
  }

  get filename () {
    return this.$store.state.printer.printer.print_stats.filename
  }

  @Watch('filename')
  onPrinterPrinting (val: string) {
    this.init(val)
  }

  mounted () {
    this.init(this.filename)
  }

  init (filename: string) {
    if (filename !== '') {
      this.tab = 0
    } else {
      this.tab = 1
    }
  }

  handlePrint (filename: string) {
    SocketActions.printerPrintStart(filename)
  }
}
